import {
    UPDATE_ORDER_STATUS_START,
    UPDATE_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS_SUCCESS
} from '../../../constants';
import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeDeclineReasonModal } from '../../siteadmin/modalActions';
import getOrderDetailsQuery from '../../../routes/shop/orderDetails/getOrders.graphql';
import { getPendingOrders } from '../../../actions/shop/orders/getPendingOrders';

export default function updateOrderStatus(id, bookingStatus, currentPage, reason, isOrderDetailPage) {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({ type: UPDATE_ORDER_STATUS_START });
            let refetchQueries = [];
            let mutation = gql`
                mutation updateOrderStatus($id: Int!, $bookingStatus: String!, $notes: String) {
                    updateOrderStatus (id: $id, bookingStatus: $bookingStatus, notes: $notes) {
                        status
                        errorMessage
                    }
                }
            `;

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    bookingStatus,
                    notes: reason
                },
                refetchQueries
            });

            if (data && data.updateOrderStatus && data.updateOrderStatus.status === 200) {
                toastr.success('Success', 'The Order status has been updated successfully!');
                dispatch(getPendingOrders());
                dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS });
            } else {
                toastr.error('Error', data && data.updateOrderStatus && data.updateOrderStatus.errorMessage);
                dispatch({ type: UPDATE_ORDER_STATUS_ERROR });
            }

        } catch (error) {
            toastr.error('Error', error);
            dispatch({ type: UPDATE_ORDER_STATUS_ERROR });
        }
    }
};