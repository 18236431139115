exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #3e0056;\n  --btn-boxShadow-bg: #3e0056;\n  --btn-bordercolor-bg: #3e0056;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #3e0056;\n  --btn-secondary-bg: #3e0056;\n  --title-color: #1a1b1c;\n  --content-color: #3a3a3a;\n  --restaurant-link-text-color: #5EBE00;\n  --auto-input-border-color: #ced4da;\n}\n\n.Z6ngt {\n  color: rgba(255, 255, 255, 0.6);\n}\n", ""]);

// exports
exports.locals = {
	"root": "Z6ngt"
};