import {
	SHOP_ORDER_START,
	SHOP_ORDER_SUCCESS,
	SHOP_ORDER_ERROR
} from '../../../constants';
import { shopCommonModalAction } from '../../modalActions';

import getPendingOrdersQuery from './getPendingOrders.graphql';


export function getPendingOrders(values) {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_ORDER_START,
				payload: {
					orderLoading: true
				}
			});

			const { data } = await client.query({
				query: getPendingOrdersQuery,
				fetchPolicy: 'network-only'
			});

			if (data && data.getPendingOrders && data.getPendingOrders.status == 200) {
				if (data.getPendingOrders.count == 0) await dispatch(shopCommonModalAction({ name: "isOrderModalOpen", value: false }));
				await dispatch({
					type: SHOP_ORDER_SUCCESS,
					payload: {
						orderLoading: false,
						data: data.getPendingOrders.results,
						count: data.getPendingOrders.count,
					}
				});

			} else {
				dispatch({
					type: SHOP_ORDER_ERROR,
					payload: {
						orderLoading: false
					}
				});
			}

		} catch (error) {
			dispatch({
				type: SHOP_ORDER_ERROR,
				payload: {
					orderLoading: false,
					error: "Something went wrong! " + error
				}
			});
		}
	}
};